<template>
  <div>
    <!-- Task Card Header -->
    <b-card class="card mb-1" title="Tambah Siswa">
      <!-- Title -->
      <div class="d-flex pb-2 border-bottom">
        <b-avatar
          :src="blobImage"
          :text="avatarText('Kelas')"
          :variant="`light-primary`"
          size="80px"
          rounded
        />
        <div class="ml-1 d-flex flex-column justify-content-center">
          <div class="d-flex flex-column mb-1">
            <h4>
              {{ formData.classroomName }}
            </h4>
            <span>{{ formData.classroomTeacher.title }}</span>
          </div>
        </div>
      </div>

      <!-- Table of Student-->
      <div class="mt-2">
        <b-row>
          <b-col lg="1">
            <b-form-select
              v-model="pageLength"
              class="w-auto mr-1"
              :options="options"
            />
          </b-col>
          <b-col></b-col>
          <b-col lg="4">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Filter</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block search-min-width"
                />
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          :sort-options="{
            enabled: true,
            initialSortBy: { field: 'fullname', type: 'asc' },
          }"
          :line-numbers="true"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Name -->
            <span v-if="props.column.field === 'fullname'" class="text-nowrap">
              <b-avatar
                variant="light-primary"
                :src="props.row.avatar"
                class="mx-1"
              />
              <span class="text-nowrap">{{ props.row.fullname }}</span>
            </span>

            <!-- Column: Status -->
            <span
              v-else-if="props.column.field === 'classroom'"
              class="d-flex justify-content-start"
            >
              <div v-if="props.row.classroom.length > 0">
                <ul>
                  <li
                    v-for="classroomItem in props.row.classroom"
                    :key="classroomItem.id"
                  >
                    {{ classroomItem.classroom.name }}
                  </li>
                </ul>
              </div>
              <div v-if="props.row.classroom.length === 0">
                <small>Belum Punya Kelas</small>
              </div>
            </span>

            <!-- Column: Action -->
            <span
              v-else-if="props.column.field === 'action'"
              class="d-flex justify-content-start"
            >
              <b-form-checkbox
                v-model="props.row.status"
                value="true"
                unchecked-value="false"
                @change="toggleStatus(props.row.id)"
              />
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <b-form-select
                  v-model="pageLength"
                  hidden
                  class="w-auto mr-1"
                  :options="options"
                  @input="props.perPageChanged({ currentPerPage: pageLength })"
                />
                <span class="text-nowrap ">
                  Showing
                  {{
                    pageLength <= props.total
                      ? pageLength * currentPage
                      : props.total
                  }}
                  of {{ props.total }} entries
                </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="
                    (value) => {
                      currentPage = value
                      props.pageChanged({ currentPage: value })
                    }
                  "
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>

        <div class="mt-2 d-flex justify-content-end">
          <b-button variant="primary" @click.prevent="handleImportStudents">
            <i class="ti ti-users" />
            <span class="align-middle ml-25">Simpan Siswa</span>
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
// * Components
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BFormTextarea,
  BFormSelect,
  BPagination,
  BFormInvalidFeedback,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { VueGoodTable } from 'vue-good-table'

// * Utils
import client from '@/libs/http/axios-config'
import { checkAuthorizeRole } from '@/auth/utils'
import { avatarText, pascalCase } from '@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
    BFormSelect,
    BPagination,

    vSelect,
    VueGoodTable,
  },
  data() {
    return {
      // State
      isLoading: false,
      isOpenClass: false,
      formErrors: {
        classroomName: { state: null, message: '' },
        classroomTeacher: { state: null, message: '' },
      },

      // Data
      classroomId: this.$router.currentRoute.params.id,

      // Form Data
      blobImage: '',
      iconList: [
        'ti-book',
        'ti-calculator',
        'ti-map-search',
        'ti-flag',
        'ti-history',
        'ti-affiliate',
      ],
      schedules: [
        'Senin',
        'Selasa',
        'Rabu',
        'Kamis',
        'Jumat',
        'Sabtu',
        'Minggu',
      ],
      classroomTeacherList: [],
      classroomYearsList: [],

      formData: {
        classroomName: '',
        selectedIcon: 'ti-book',
        classroomEnrollKey: '',
        classroomImage: '',
        classroomCover: '',
        classroomSchedule: 'Senin',
        classroomTeacher: null,
        classroomYear: null,
        classroomDescription: '',
        classroomStartDate: '',
        schoolId: '',
      },
      temporaryEnrollKey: '',

      // Table option
      searchTerm: '',
      currentPage: 1,
      pageLength: 10,
      options: ['10', '20', '30'],
      columns: [
        {
          label: 'Nama Siswa',
          field: 'fullname',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Telepon',
          field: 'phone',
        },
        {
          label: 'Kelas',
          field: 'classroom',
        },
        {
          label: 'Aksi',
          field: 'action',
          width: '5%',
        },
      ],
      rows: [],

      studentLists: [],
    }
  },
  async mounted() {
    this.formData.classroomStartDate = new Date().toISOString().slice(0, 10)
    this.formData.schoolId = JSON.parse(
      localStorage.getItem('userData')
    ).school_id

    // * Get Classroom Data
    await client
      .get(`/school-admin/classrooms/${this.classroomId}`)
      .then((res) => {
        this.formData.classroomName = res.data.data.name
        this.formData.selectedIcon = res.data.data.logo
        this.formData.classroomEnrollKey = res.data.data.enroll_key
        this.formData.classroomImage = res.data.data.image
        this.blobImage = res.data.data.image
        this.formData.classroomCover = res.data.data.image
        this.formData.classroomSchedule = res.data.data.schedule
        this.formData.classroomTeacher = {
          title: this.pascalCase(res.data.data.teacher.name),
          value: res.data.data.teacher.id,
        }
        this.formData.classroomYear = this.classroomYearsList.find(
          (year) => year.value === res.data.data.school_year_id
        )

        this.formData.classroomDescription = res.data.data.description
        this.formData.classroomStartDate = res.data.data.start_date
        this.formData.classroomEnrollKey = res.data.data.enrollment_key
        this.temporaryEnrollKey = res.data.data.enrollment_key

        if (res.data.data.enrollment_key === null) {
          this.isOpenClass = true
        }
      })

    await client
      .get('/school-admin/users?role=Student&per_page=99999999&status=Active')
      .then((response) => {
        const students = response.data.data.data

        const listOfStudents = students.map((student) => {
          return {
            id: student.id,
            fullname: pascalCase(student.name),
            avatar: student.avatar,
            phone: student.phone,
            classroom: student.student_classrooms,
            email: student.email,
            status: student.student_classrooms.find(
              (classroom) => classroom.classroom_id === this.classroomId
            )
              ? true
              : false,
          }
        })

        this.rows = listOfStudents

        this.studentLists = students.map((student) => {
          return {
            id: student.id,
            status: student.student_classrooms.find(
              (classroom) => classroom.classroom_id === this.classroomId
            )
              ? true
              : false,
          }
        })

        this.isLoading = false
      })
      .catch((error) => {
        console.log(error)

        this.showToast(
          'error',
          'XIcon',
          'Gagal',
          'Data Gagal Dimuat, Silahkan Coba Lagi'
        )
      })
  },
  methods: {
    avatarText,
    pascalCase,
    checkAuthorizeRole,
    generateRandomString(length) {
      let result = ''
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < length; i += 1) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        )
      }
      return result
    },
    isOpenClassChange() {
      if (this.isOpenClass) {
        this.formData.classroomEnrollKey = null
      } else {
        if (this.temporaryEnrollKey === 'null') {
          this.formData.classroomEnrollKey = this.generateRandomString(6)
        } else {
          this.formData.classroomEnrollKey = this.temporaryEnrollKey
        }
      }
    },
    onFileChange(e) {
      const file = e.target.files[0]
      this.formData.classroomLogo = file
      this.blobImage = URL.createObjectURL(file)
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },

    toggleStatus(studentId) {
      const selected = this.studentLists.find(
        (student) => student.id === studentId
      )

      selected.status = !selected.status
    },

    async handleImportStudents() {
      const data = JSON.stringify({
        students: this.studentLists.filter(
          (student) => student.status === true
        ),
      })

      const config = {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'multipart/form-data',
        },
      }

      await client
        .post(
          `/school-admin/classrooms/${this.classroomId}/bulk-students`,
          data,
          config
        )
        .then((response) => {
          console.log(response.data)
          this.showToast(
            'success',
            'CheckIcon',
            'Berhasil',
            'Data Berhasil Disimpan'
          )
        })
        .catch((error) => {
          this.showToast(
            'error',
            'XIcon',
            'Gagal',
            'Data Gagal Disimpan, Silahkan Coba Lagi'
          )
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '~@core/scss/vue/libs/vue-good-table.scss';

.line-height-small {
  line-height: 1.5rem;
}

.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.pointer {
  cursor: pointer;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.vs__clear {
  display: none;
}
</style>
